import React, {useEffect, useState } from 'react'
import '../Admin.css';
import {  useNavigate } from "react-router-dom";
import axios from 'axios'
import { useParams } from 'react-router-dom';
//Material UI components
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Loader from "../util/Loader";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';



  const initialValues = {
	sqlText: "",
	metaData: "",
	columnMap: '',
	id: '',
	name: '',
	//chart configurations
	chartTitle: '',
	yAxisType: '',
	yAxisTitle: '',
	yAxisLabelsFormat: '',
	yAxisDecimalPlaces:'0',
	yAxisMin: '',
	chartTooltip: '',
	dataLabelsFormat: '',
	dataLabelsDecimalPlaces: '',
	chartColors: ''
  };


export default function ChartEdit() {

	//set the values of inputs to the constant
	//console.log('RENDER');
	const [values, setValues] = useState(initialValues);
	const [yaxistype, setYaxistype] = useState('');

	const handleSelectChange = (event) => {
		setYaxistype(event.target.value);
	};

	const navigate = useNavigate();

    //const [setChart] = useState({});
	const[title, setTitle] = useState('');
	//const[setBaseUri] = useState('');
	//const[setAppEnv] = useState('');
	const[loading, setLoading] = useState(true);
	const { id } = useParams();

    useEffect( () => {

		async function getChart() {
			//console.log(queries);
			let chartId = {id};
			if(id !== 'create'){
	
				//let baseUri = process.env.NODE_ENV === 'production' ? "api.vtkidsdata.org" :  "api.qa.vtkidsdata.org";
				let baseUri = process.env.REACT_APP_API_URL;
				//let appEnv = process.env.REACT_APP_APP_ENV;
				//setBaseUri(baseUri);
				//setAppEnv(appEnv);
				let endpoint = `${baseUri}/queries/`+chartId.id;
				console.log('GETTING CHART TO EDIT', endpoint);
				let chart = await axios.get(endpoint,{withCredentials: true} );
				console.log('CHART',chart);
				let metaData = chart?.data?.row?.metadata != null ? chart?.data?.row?.metadata : '{}';
				let sqlText = chart.data.row.sqlText;
				let columnMap = chart.data.row.columnMap != null ? chart.data.row.columnMap : '';
				let id =chart.data.row.id;
				let name =chart.data.row.name;
				let newvalues = {};
				newvalues.metaData = metaData;
				newvalues.sqlText = sqlText;
				newvalues.id = id;
				//newvalues.name = name;
				newvalues.name = name;
				newvalues.columnMap = columnMap

				//set chart configurations
				let metaJson = JSON.parse(metaData);
				console.log('META DATA', metaJson);
				newvalues.chartTitle = metaJson?.title != null ? metaJson?.title : '';
				let axisType = metaJson?.yAxis?.type != null ? metaJson?.yAxis?.type : 'percent';
				setYaxistype(axisType);
				newvalues.yAxisTitle = metaJson?.yAxis?.title != null ? metaJson?.yAxis?.title : '';
				newvalues.yAxisLabelsFormat = metaJson?.yAxis?.labels?.format != null ? metaJson?.yAxis?.labels?.format : '';
				newvalues.yAxisDecimalPlaces = metaJson?.yAxis?.decimalPlaces != null ? metaJson?.yAxis?.decimalPlaces : '';
				newvalues.chartTooltip = metaJson?.tooltip != null ? metaJson?.tooltip : '';
				newvalues.dataLabelsFormat = metaJson?.plotOptions?.series?.dataLabels?.format != null ? metaJson?.plotOptions?.series?.dataLabels?.format : '';
				newvalues.dataLabelsDecimalPlaces = metaJson?.dataLabelsDecimalPlaces != null ? metaJson?.dataLabelsDecimalPlaces : '';
				newvalues.chartColors = metaJson?.colors != null ? metaJson?.colors : '';
				newvalues.yAxisMin = metaJson?.yAxis?.min != null ? metaJson?.yAxis?.min : '';
				setValues(newvalues);
				//setValues(values => [...values, newvalues]);		 
				setTitle(newvalues.chartTitle);
				//setChart(chart.data.row);
			}
			//setValues(values);	
			setLoading(false);
			//console.log('VALUES',values);
		}
		
        getChart();
        
    },[id]);

	const handleInputChange = (e) => {
		//console.log(e.target);
		const { name, value } = e.target;
		setValues({
		  ...values,
		  [name]: value,
		});
	  };

    const saveChart = async(event) => {

		
		console.log('saving');
		let data = {};
		data.name = values.name;
		data.sqlText = values.sqlText;
		data.metadata = values.metaData;
		data.columnMap  = values.columnMap;

		console.log(values.metaData);
		let metaJson = JSON.parse(values.metaData);
		let meta = {};
		if(values.metaData !== null && values.metaData !== ''){
			meta = metaJson;
		}
		//console.log('VALUES',values);
		console.log('META JSON',metaJson);
		//---------->chart title
		if(values.chartTitle !== null && values.chartTitle !== ''){
			meta.title = values.chartTitle;
		}
		//---------->y axis type
		meta.yAxis = meta.yAxis || {};
		meta.yAxis.labels = meta.yAxis.labels || {};
		meta.plotOptions = meta.plotOptions || {};
		meta.plotOptions.series = meta.plotOptions.series || {};
		meta.plotOptions.series.dataLabels = meta.plotOptions.series.dataLabels || {};
		meta.yAxis.type = yaxistype;
		//---------->y axis min value
		if(values.yAxisMin !== null && values.yAxisMin !== ''){
			meta.yAxis.min = values.yAxisMin;
		}
		//---------->y axis title
		if(values.yAxisTitle !== null && values.yAxisTitle !== ''){
			meta.yAxis.title = values.yAxisTitle;
		}
		//---------->y axis decimal places
		if(values.yAxisDecimalPlaces !== null && values.yAxisDecimalPlaces !== ''){
			meta.yAxis.decimalPlaces = values.yAxisDecimalPlaces;
		}

		//---------->data labels decimal places
		if(values.dataLabelsDecimalPlaces !== null && values.dataLabelsDecimalPlaces !== ''){
			meta.dataLabelsDecimalPlaces = values.dataLabelsDecimalPlaces;
		}

		//---------->tooltip
		//if(values.chartTooltip !== null && values.chartTooltip !== ''){
			meta.tooltip = values.chartTooltip;
		//}

		console.log('NEW META JSON', meta);
		data.metadata = JSON.stringify(meta);

		let baseUri = process.env.REACT_APP_API_URL;
		let endpoint = `${baseUri}/queries`;
		const headers = {
			'Content-Type': 'application/json'
		};

		/*
        
		setLoading(true);
		let data = {};
		data.name = values.name;
		data.sqlText = values.sqlText;
		data.metadata = values.metaData;
		data.columnMap  = values.columnMap;
		let baseUri = process.env.REACT_APP_API_URL;
		let endpoint = `${baseUri}/queries`;
		const headers = {
			'Content-Type': 'application/json'
		};
		console.log('DATA', data);
		let metaJson = JSON.parse(values.metaData);
		console.log(metaJson);

		setLoading(false);
		//return true;

		//build the meta JSON
		let meta = {}

		
		meta.yAxis = meta.yAxis || {};
		meta.yAxis.labels = meta.yAxis.labels || {};
		meta.plotOptions = meta.plotOptions || {};
		meta.plotOptions.series = meta.plotOptions.series || {};
		meta.plotOptions.series.dataLabels = meta.plotOptions.series.dataLabels || {};
		meta.title = values.chartTitle
		meta.yAxis.type = yaxistype;
		meta.yAxis.title = values.yAxisTitle
		meta.yAxis.labels.format = values.yAxisLabelsFormat
		meta.tooltip = values.chartTooltip
		meta.plotOptions.series.dataLabels.format = values.dataLabelsFormat;
		meta.colors = values.chartColors;
		data.metadata = JSON.stringify(meta);
		//
		
		//values.metaData = JSON.stringify(meta);
		//console.log('META', meta);
		return false;
		*/
		
		try{
			let res = {};
			if(values.id === null || values.id === ''){
				res = await axios.post(endpoint, data, {headers, withCredentials: true});
				console.log(res.data);
			} else {
				console.log('------------------->Updating Chart Config')
				endpoint += `/${values.id}`;
				console.log('Update Endpoint', endpoint);
				res = await axios.put(endpoint, data, {headers, withCredentials: true});
				console.log(res.data);
				
			}
			
			
		} catch(e){
			console.log(e.message);
		}
		
		
    }

    return (

        <div style={{textAlign: "left"}}>
			<Container>
            <h3>Edit Chart</h3>
			<Loader open={loading} />	
			<h1>{title}</h1>
			<Box
				component="form"
			>
				<h3>Chart Configuration Options</h3>
				

				<Button variant="contained" className="App-icon-spacing" onClick={saveChart}>Save</Button>
				<Button variant="contained" onClick={() => navigate(-1)}>Back</Button>
				<h4>Chart Configuration</h4>
				<div>
				<div>The title of the chart, displayed at the top</div>
				<TextField 
						id="chartTitle" 
						label="Chart Title" 
						variant="outlined" 
						value={values.chartTitle} 
						name="chartTitle"
						onChange={handleInputChange} 
						className="App-textarea"
					/>
				</div>
<div>The display type of the y axis - this will either show % values or regular number counts</div>
				<div>
				<FormControl className="App-textarea">
				<InputLabel id="yAxisType">y Axis Type</InputLabel>
				<Select
				labelId="yAxisType"
				id="yAxisType"
				value={yaxistype}
				label="y Axis type"
				onChange={handleSelectChange}
				style={{ width: 200 }} 
				>
				<MenuItem value={"number"}>Number</MenuItem>
				<MenuItem value={"percent"}>Percent</MenuItem>
				</Select>
				</FormControl>

				<div>The title that will be displayed on the y axis</div>
				</div>
				<TextField 
						id="yAxisTitle" 
						label="Y Axis Title" 
						variant="outlined" 
						value={values.yAxisTitle} 
						name="yAxisTitle"
						onChange={handleInputChange} 
						className="App-textarea"
					/>

					<div>The number of decimal places on the y axis </div>
					<TextField 
						id="yAxisDecimalPlaces" 
						label="Y Axis Label Decimal Places" 
						variant="outlined" 
						value={values.yAxisDecimalPlaces} 
						name="yAxisDecimalPlaces"
						onChange={handleInputChange} 
						className="App-textarea-small"
					/><br/>

				<div>The minimum (starting) value of the y axis</div>
					<TextField 
						id="yAxisMin" 
						label="Y Axis Minimum Value" 
						variant="outlined" 
						value={values.yAxisMin} 
						name="yAxisMin"
						onChange={handleInputChange} 
						className="App-textarea-small"
					/>
					<br/>
					<div>The number of decimal places displayed in the data labels</div>
					<TextField 
						id="dataLabelsDecimalPlaces" 
						label="Data labels decimal places" 
						variant="outlined" 
						value={values.dataLabelsDecimalPlaces} 
						name="dataLabelsDecimalPlaces"
						onChange={handleInputChange} 
						className="App-textarea-small"
					/><br/>

					<TextField 
						id="chartTooltip" 
						label="Chart Tooltip" 
						multiline 
						variant="outlined" 
						name="chartTooltip"
						value={values.chartTooltip} 
						style={{ width: 800 }} 
						onChange={handleInputChange} 
						className="App-textarea"
					/>
					<div>Tooltips are a string that can include javascript variables that get evaluated at run time.  When 
						entering a tooltip value, it should be enclosed in single quotes, with the javacript variables NOT in 
						the quotes<br/><br/><b> e.g.:  'The value of the y axis is: '+ this.y</b><br/><br/>In the above example, 
						this.y is the javascript variable.  Available javascript data in the tooltip are: <br/><br/>
						Available data are:<br/>
<ul>
<li>this.percentage: Stacked series and pies only. The point's percentage of the total.</li>

<li>this.point: The point object. The point name, if defined, is available through this.point.name.</li>

<li>this.points: In a shared tooltip, this is an array containing all other properties for each point.</li>

<li>this.series: The series object. The series name is available through this.series.name.</li>

<li>this.total: Stacked series only. The total value at this point's x value.</li>

<li>this.x: The x value. This property is the same regardless of the tooltip being shared or not.</li>

<li>this.y: The y value.</li>
</ul>
					</div>
					
	<div className="internal">			
					<h3>Internal Database Values</h3>
					<div>These values are what is stored in the database.  <b>NOTE</b> that while you can edit these directly, there 
						generally is no need to do so
					</div>
<div className="chart-edit-help">
				<b>Internal Database ID:</b> {values.id}
				</div>
				<div>
				<div className="chart-edit-help">The internal name of the chart - this is the last segment of the URL, e.g. /columnchart/%%NAME%%</div>
				<TextField 
						id="name" 
						label="Name" 
						multiline 
						variant="outlined" 
						value={values.name} 
						name="name"
						onChange={handleInputChange} 
						className="App-textarea"
					/>
				</div>
				<div className="chart-edit-help">The raw SQL query to get the chart data</div>
				<div >
					<TextField 
						id="sql-text" 
						label="SQL Query" 
						multiline 
						variant="outlined" 
						value={values.sqlText} 
						name="sqlText"
						onChange={handleInputChange} 
						className="App-textarea"
					/>
				</div>
				<div className="chart-edit-help">Mappings of database names to different column names</div>
				<div>
					<TextField 
						id="column-map" 
						label="Column Map" 
						multiline 
						variant="outlined" 
						value={values.columnMap} 
						name="columnMap"
						style={{ width: 800 }} 
						onChange={handleInputChange} 
						className="App-textarea"
					/>
				</div>
				<div className="chart-edit-help">JSON representation of chart properties stored in the database</div>
				<div>
					<TextField 
						id="meta-data" 
						label="Chart Properties" 
						multiline 
						variant="outlined" 
						name="metaData"
						value={values.metaData} 
						style={{ width: 800 }} 
						onChange={handleInputChange} 
						className="App-textarea"
					/>
				</div>

				</div>	

			</Box>
			</Container>
        </div>
    );
}