import React, {useState} from 'react';
import axios from 'axios';
import AssistantLoader from "../util/AssistantLoader";
//import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import headerAvatar from '../assets/images/vtkids-logo-avatar.png';

//import config from './bot/config.js';
//import MessageParser from './bot/MessageParser.js';
//import ActionProvider from './bot/ActionProvider.js';

import ChatBot from "react-chatbotify";

import './VkdAssistant.css';
//import { px } from 'framer-motion';

import showdown  from 'showdown';
let converter = new showdown.Converter();

const id = new Date().getTime();

export default function VkdAssistant(){



const baseApiUri = process.env.REACT_APP_API_URL;
const [isLoading, setIsLoading] = useState(false);
//const [isVisible, setIsVisible] = useState(true);
const [inputText, setInputText] = useState("");
//const [openAiResponse, setOpenAiResponse] = useState("");
//const [openAiQuestion, setOpenAiQuestion] = useState("");
const[open, setOpen] = useState(false);
const[sortKey, setSortKey] = useState(0);
//const[id, setId] = useState(new Date().getTime());
//setId((new Date()).getTime());

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

//const handleInputChange = (event) => {
   // setInputText(event.target.value);
//};




let hasError = false;
//let apiKey = null;

const call_openai = async (params) => {
 //await params.injectMessage("this is the content of my message");
 let question = params.userInput;
 console.log(question); 
 
 //let id =  (new Date()).getTime();
 console.log(id);
 //return false;
 console.log(inputText);
 //let question = inputText;
 //setOpenAiQuestion(question);
 console.log('QUESTION', question);
 let message = '';
 setInputText('');
 console.log('SORTKEY', sortKey);

 if(question != null && question !== ''){
     let endpoint = `${baseApiUri}/ai/completion`;
     let payload = {
         "key": "09848734-8745-afrt-8745-8745873487",
         "id": id,
         "sortKey": parseInt(sortKey),
         "query": question,
         "stream": true
     }
     console.log('PAYLOAD', payload)
     let response = await axios.post(endpoint, payload, {});
     console.log(response.data);
     let hasMore = true;
     setIsLoading(false);
     //setIsVisible(true);
     setOpen(false);
     //setOpenAiResponse('One moment while I analyze data from VermontKidsData....');
     while(hasMore){
         //console.log('polling completion');
         try{
             let endpoint =  `${baseApiUri}/ai/completion/${id}/${sortKey}`;
             let completion = await axios.get(endpoint);
             //console.log(completion.data);
             try{
                 let status = completion.data.response.status;
                 message = completion.data.response.message;
                 //console.log('MESSAGE', message);
                 if(message !== '' && message != null){
                     //setOpenAiResponse(message);
                     let messageHtml = converter.makeHtml(message);
                     await params.streamMessage(messageHtml.replaceAll('<a','<a target="_blank" '));
                     
                 }
                 if(status === 'success'){
                     hasMore = false;
                     
                 }
             } catch(e){
                 hasMore = false;
             }
             
             
             await sleep(100);
         } catch(e){
             hasMore = false;
             //setOpen(false);
         }
     }
     
 } else {
     message = 'please enter a question';
 }
let newSortKey = sortKey;
setSortKey(newSortKey+1);
 //console.log(message);

}


const options = {
  isOpen: true,
  theme: {
    primaryColor: "#1d2e28",
    secondaryColor: "#007155",
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', " +
        "'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', " +
        "sans-serif",
    showHeader: true,
    showFooter: true,
    showInputRow: true,
    embedded: false,
    desktopEnabled: true,
    mobileEnabled: true,
  },
  chatHistory: {storageKey: "vkd_conversation"},
  botBubble: {dangerouslySetInnerHtml: true},
  emoji: {
    disabled: true
  },
  notification: {
    disabled: true
  },
  header: {
    title: (
        <h3 style={{cursor: "pointer", margin: 0}} onClick={
            () => window.open("https://github.com/tjtanjin/")
        }>VermontKidsData AI Assistant
        </h3>
    ),
    showAvatar: true,
    avatar: headerAvatar
  },
  footer: {
    text: (
      <div style={{cursor: "pointer"}} 
        onClick={() => window.open("https://vermontkidsdata.org")}
      >
        <span>Powered By </span>
        <span style={{fontWeight: "bold"}}>
          <span>Building Bright Futures and vermontkidsdata.org</span>
        </span>
      </div>
    ),
  },
  chatWindowStyle: { 
      width: 1000,
      position: 'top' 
  }

};

const flow={
  start: {
    message: "Hi I'm the AI Assistant for VermontKidsData.org and can help answer questions about Vermont's young children!",
    path: "loop",
  },
  loop: {
    message: async (params) => {
      await call_openai(params);
    },
    path: () => {
      if (hasError) {
        return "start"
      }
      return "loop"
    }
  }
}

return (
    <div>
        <AssistantLoader open={open} />


      {isLoading && <p style={{ fontFamily: 'DM Sans, sans-serif', marginLeft: '12px' }}></p>}

    <div style={{ width: '100%'}}>
      <center><ChatBot  options={options} flow={flow} /></center>
    </div>

    

      </div>
)
        }