import React, {useState, useEffect } from 'react'
import {  useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import '../dashboard/Dashboard.css';
import Button from '@mui/material/Button';
import axios from 'axios'
//import Container from '@mui/material/Container';
import Loader from "../util/Loader";
import TextField from '@mui/material/TextField';
//import { DataGrid } from '@mui/x-data-grid';
//icons
//import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
//import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const baseApiUri = process.env.REACT_APP_API_URL;

const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%', // Adjust the height as needed
    padding: '20px',
  };

  const columnStyle = {
    flex: '2',
    padding: '10px',
    boxSizing: 'border-box',
  };

let initialValues = {
    description: "",
    name: "",
    categorydescription: "",
    categoryname: "",
    categorytype: "",
    charttype: "",
    query: "",
    defaultSeries: ""
};

let payload = {
    "assistantId":"1725111263847",
    "_vkd":{
        "urlPath":"linechart/children_in_poverty_under_12:chart",
        "query":"children_in_poverty_under_12_all:chart"},
        "entity":"AssistantFunction","name":"children_in_poverty_under_12_all-chart",
        "created":"2024-08-31T13:34:23.941Z",
        "functionId":"1725111263940",
        "seriesParameter":{
            "name":"location",
            "description":"The location, either a Building Bright Futures region within Vermont, or across the entire state. Should either be the region name \n          (e.g. Chittendon) or the word 'Vermont' for poverty across the entire state. It returns a JSON object with four fields: \n          \"value\" which gives the requested value, \"url\" which gives a URL for a dataset you can reference for more information, \n          \"geography\" which is the geography the value is for, and \"year\" which is the year the data is returned for. The geography \n          and year might be different from what you requested if the function can't return the value for the requested geography or year.",
            "type":"string",
            "_vkd":{
                "type":{
                    "type":"series"
                }
            },"enum":["Addison","Bennington","Caledonia and Southern Essex","Central Vermont","Chittenden","Franklin Grand Isle","Lamoille Valley","Northern Windsor and Orange","Orleans and Northern Essex","Rutland","Southeast Vermont","Springfield","Vermont"]
        },
        "modified":"2024-08-31T13:34:23.941Z",
        "categoryParameter":{
            "name":"year",
            "description":"The year of interest. Should be greater than or equal to 2017. If the request is for before 2017, request data\n          for 2017 and tell the user that the data is only available from 2017 onwards.",
            "type":"string",
            "_vkd":{
                "type":{
                    "type":"category"
                }
            }
        },
        "description":"Determine percent of children in poverty within Vermont for a given year. It returns the percent \n    of children in poverty for a certain year. It can give the information either for a particular AHS region in Vermont, or across the\n    entire state. Returns a JSON object with two properties: a 'value' which is the value requested, and a 'url' which is the URL of\n    a chart that allows to user to explore the data in more detail. If the requested year is not available, use the earliest year available.",
        "otherParameters":[]
    };

export default function AssistantFunctionAdmin(){

    const[loading, setLoading] = useState(false);
    //const[portals, setPortals] = useState();
    const [assistantFunction, setAssistantFunction] = useState({});
    const { id } = useParams();
    const { assistant } = useParams();
    const [values, setValues] = useState(initialValues);

    const navigate = useNavigate();

   const handleInputChange = (e) => {
    //console.log(e.target);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const cancel = (e) => {
    navigate(`/assistantadmin/${assistant}`)
  };

  const saveFunction = (e) => {
    payload.name = values.name;
    console.log(payload);
  };

    useEffect( () => {
        
        async function loadFunction() {

            setLoading(true);
            //let endpoint = `${baseApiUri}/dashboard/check`;
            let endpoint = `${baseApiUri}/ai/assistant/1725111263847/function/${id}?key=09848734-8745-afrt-8745-8745873487`;
            console.log(endpoint);
            let res = await axios.get(endpoint);
            console.log(res.data);
            let currentFunction = {};
            currentFunction = res.data.function;
            console.log(JSON.stringify(currentFunction));
            let newvalues = {};
            newvalues.name = res.data.function.name;
            newvalues.description = res.data.function.description;
            newvalues.categoryname = res.data.function.categoryParameter.name;
            newvalues.categorytype = res.data.function.categoryParameter.type;
            newvalues.categorydescription = res.data.function.categoryParameter.description;
            setValues(newvalues);
            //setPortals(res.data);

            
            setAssistantFunction(currentFunction);

            setLoading(false);
    
        }

        loadFunction();
    } , [id])

    return (
        <>

<div style={{textAlign: "left"}}>
<Loader open={loading} />

<Box sx={{padding: '20px'}}>

<div>
    <h1>Manage Assistant Function</h1>
</div>

<div style={containerStyle}>

    <div style={columnStyle}>

    <h2>Assistant Function Details: {assistantFunction.name}</h2>

    <TextField 
    id="name" 
    label="Name" 
    variant="outlined" 
    value={values.name} 
    name="name"
    onChange={handleInputChange} 
    className="App-textarea"
    />

    <TextField 
        id="description" 
        label="Description" 
        multiline 
        variant="outlined" 
        value={values.description} 
        name="description"
        onChange={handleInputChange} 
        className="App-textarea"
    />

    </div>

    <div style={columnStyle}>
    <Button  style={{marginRight:'5px'}}  variant="contained" onClick={saveFunction}>Save Function</Button>   
    <Button  style={{marginRight:'5px'}}  variant="contained" onClick={cancel}>Cancel</Button>
    </div>


</div>

</Box>

</div>
        

        </>
    )


    
}