import React, {useState, useEffect } from 'react'
import {  useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import '../dashboard/Dashboard.css';
import Button from '@mui/material/Button';
import axios from 'axios'
//import Container from '@mui/material/Container';
import Loader from "../util/Loader";
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
//icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
//import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import call_openai from 'openai/Assistant';

const baseApiUri = process.env.REACT_APP_API_URL;

const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%', // Adjust the height as needed
    padding: '20px',
  };

  const columnStyle = {
    flex: '2',
    padding: '10px',
    boxSizing: 'border-box',
  };

let initialValues = {
    description: "",
    name: "",
    instructions: "",
    sandboxname: "",
    question: "",
    response: ""
};

//let payload = {};

export default function AssistantAdmin(){

    const[loading, setLoading] = useState(false);
    const[showSandbox, setShowSandbox] = useState(false);
    //const[portals, setPortals] = useState();
    const [assistant, setAssistant] = useState({});
    const { id, sandbox } = useParams();
    const [values, setValues] = useState(initialValues);
    const [rows, setRows] = useState([]);
    const [sandboxrows, setSandboxows] = useState([]);
    const [open, setOpen] = React.useState(false);
    const[chatResponse, setChatResponse] = useState();
    const [isSandbox, setIsSandbox] = useState(false);

    const navigate = useNavigate();
    let  columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
          field: 'name',
          headerName: 'Name',
          flex: 3,
          editable: true,
        },
        {
            field: 'edit',
            headerName: '',
            renderCell: ({ row }) => (
              <strong>
                 <EditOutlinedIcon className="App-icon-spacing App-icon-cursor" onClick={() => navigate(`/assistantfunctionadmin/${assistant.id}/${row.id}`)} />
              </strong>
            ),
          }
          
         
      ];

      let  sandboxcolumns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
          field: 'sandbox',
          headerName: 'Name',
          flex: 3,
          editable: true,
        },
        {
            field: 'edit',
            headerName: '',
            renderCell: ({ row }) => (
              <strong>
                 <ArrowCircleRightIcon className="App-icon-spacing App-icon-cursor" onClick={() => navigate(`/assistantadmin/${row.id}`)} />
              </strong>
            ),
          }
          
         
      ];
    
     // const handleClickOpen = (portalId) => {
       // console.log('OPEN CHART ID',portalId);
   // };

   const handleInputChange = (e) => {
    //console.log(e.target);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const cancel = (e) => {
    navigate(`/assistantsadmin`)
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const createSandbox = async (e) => {
    let payload = {};
    payload.sandbox = values.sandboxname;
    console.log(payload);
    //clone the existing assistant
    try{
        let endpoint = `${baseApiUri}/ai/assistant/${id}/clone?key=09848734-8745-afrt-8745-8745873487`;
        console.log(endpoint);
        let res = await axios.post(endpoint, payload, {});
        console.log(res.data);
        let sandboxId = res.data.assistantId;
        setShowSandbox(false);
        navigate(`/assistantadmin/${sandboxId}`);

    } catch(e){
        console.log(e);
        //navigate(`/assistantadmin/${id}`);
    }
    //navigate(`/assistantadmin/${id}`);
    
  };


  const saveAssistant = async (e) => {
    
    
  };

  const testAssistant = (e) => {
    setOpen(true);
  };

    useEffect( () => {

        async function loadSandboxes(){

            let endpoint = `${baseApiUri}/ai/assistant?key=09848734-8745-afrt-8745-8745873487`;
            console.log(endpoint);
            let res = await axios.get(endpoint);
            console.log(res.data.assistants);
            let rows = [];
            for(let i = 0; i < res.data.assistants.length; i++){
                let a = res.data.assistants[i];
                if(a.hasOwnProperty('sandbox')){
                    rows.push(a);
                }
            }

            setSandboxows(rows);
        }
        
        async function loadAssistant() {

            setLoading(true);
            //let endpoint = `${baseApiUri}/dashboard/check`;
            let endpoint = `${baseApiUri}/ai/assistant/${id}?key=09848734-8745-afrt-8745-8745873487`;
            console.log(endpoint);
            let res = await axios.get(endpoint);
            console.log(res.data);
            let currentAssistant = {};
            currentAssistant = res.data.assistant;
            let newvalues = {};
            newvalues.name = res.data.assistant.name;
            newvalues.description = res.data.assistant.definition.description;
            newvalues.instructions = res.data.assistant.definition.instructions;
            newvalues.sandboxname = '';
            newvalues.question = '';
            newvalues.response = '';
            setValues(newvalues);
            //setPortals(res.data);

            let endpointF = `${baseApiUri}/ai/assistant/${id}/function/?key=09848734-8745-afrt-8745-8745873487`;
            console.log(endpointF);
            let resF = await axios.get(endpointF);
            console.log(resF.data);
            currentAssistant.functions = resF.data.functions;
            let functions = [];
            for(let i = 0; i < resF.data.functions.length; i++){
                let f = resF.data.functions[i];
                f.id = f.functionId;
                functions.push(f);
            }
            setRows(functions)
            console.log('ASSISTANT', currentAssistant)
            setAssistant(currentAssistant);
            if(currentAssistant.hasOwnProperty('sandbox')){
                setIsSandbox(true);
            }

            setLoading(false);
    
        }
        console.log('SANDBOX', sandbox);
        if(sandbox ==='sandbox'){

            setShowSandbox(true);
        } else {
            setShowSandbox(false);
        }

        loadAssistant();
        loadSandboxes();

    } , [id, sandbox])

    return (
       
        <div style={{textAlign: "left"}}>

        <Loader open={loading} />

        { showSandbox ? (

            <>
            <div>
                <h1>Create or select a sandbox</h1>
            </div>
            <div style={containerStyle}>
                <div style={columnStyle}>
                    <h2>Create New Sandbox</h2>
                    <div >
                        <TextField 
                            id="sandboxname" 
                            label="Sandbox Name" 
                            variant="outlined" 
                            value={values.sandboxname} 
                            name="sandboxname"
                            onChange={handleInputChange} 
                            className="App-textarea"
                        />
                    </div>
                    <div>
                        <Button  style={{marginRight:'5px'}}  variant="contained" onClick={createSandbox}>Create Sandbox</Button>
                    </div>

                </div>
            
                <div style={columnStyle}>
                <h2>Select an Existing Sandbox</h2>
                <div>
                        <Box sx={{ height: 500, width: '100%', backgroundColor: '#ffffff' }}>
                            <DataGrid
                                rows={sandboxrows}
                                columns={sandboxcolumns}
                                initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 5,
                                    },
                                },
                                }}
                                pageSizeOptions={[5]}
                                checkboxSelection
                                disableRowSelectionOnClick
                            />
                            </Box>
                        </div>
                    
                </div>
                

            </div>
            
            </>


            ) : (
        
			
        <Box sx={{padding: '20px'}}>
        <div>
        {isSandbox ? (
 <Alert severity="warning">You are currentnly working in a sandbox Assistant</Alert>
        ) : (<></> ) }
           
            <h1>Manage Assistant</h1>
        </div>

        <div style={containerStyle}>
      <div style={columnStyle}>
        <h2>Assistant Details: {assistant.name}</h2>
               <div >
        <TextField 
            id="name" 
            label="Name" 
            variant="outlined" 
            value={values.name} 
            name="name"
            onChange={handleInputChange} 
            className="App-textarea"
        />
		</div>

        <div >
        <TextField 
            id="description" 
            label="Description" 
            multiline 
            variant="outlined"
            rows={10}
            value={values.description} 
            name="description"
            onChange={handleInputChange} 
            className="App-textarea"
        />
		</div>
        <div >
        <TextField 
            id="instructions" 
            label="Instructions" 
            multiline 
            rows={50}
            variant="outlined" 
            value={values.instructions} 
            name="instructions"
            onChange={handleInputChange} 
            className="App-textarea"
        />
		</div>
        
      </div>
      <div style={columnStyle}>
      <Button  style={{marginRight:'5px'}}  variant="contained" onClick={testAssistant}>Test Assistant</Button>
      <Button  style={{marginRight:'5px'}}  variant="contained" onClick={saveAssistant}>Save Assistant</Button>   
      <Button  style={{marginRight:'5px'}}  variant="contained" onClick={cancel}>Cancel</Button>
        <h2>Assistant Functions</h2>
        
        <div>
            <Box sx={{ height: 500, width: '100%', backgroundColor: '#ffffff' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
                </Box>
            </div>
        
      </div>
    </div>

    <Modal
        open={open}
        onClose={handleClose}
        >
           
            <Box sx={modalstyle}>
            <div style={{margin:'10px'}}  >
            <h3>Test Assistant</h3>
            <Button  style={{marginRight:'5px'}}  variant="contained" onClick={() => call_openai(setChatResponse,values.question, isSandbox, assistant.sandbox)}>Ask</Button>
            <Button  style={{marginRight:'5px'}}  variant="contained" onClick={handleClose}>Close</Button>
            <div >
  
        <TextField 
            id="question" 
            label="Assistant Question" 
            variant="outlined" 
            value={values.question} 
            name="question"
            onChange={handleInputChange} 
            className="App-textarea"
        />
		</div>
        <div>

        <div >
        {/* Set innerHTML using dangerouslySetInnerHTML */}
        <div dangerouslySetInnerHTML={{ __html: chatResponse }} />
        </div>

		</div>
            </div>
            </Box>
                </Modal>


        </Box>

                )
            }

        </div>
       


    )


    
}