// assets
import { IconDatabaseImport, IconTypography, IconPalette, IconShadow, IconWindmill,IconChartBar,IconDashboard,IconUsers } from '@tabler/icons-react';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconChartBar,
    IconDashboard,
    IconUsers,
    IconDatabaseImport
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Administration',
    caption: 'Manage datasets and dashboards',
    type: 'group',
    children: [
        {
            id: 'datasets',
            title: 'Datasets',
            type: 'item',
            url: '/datasets',
            icon: icons.IconDatabaseImport,
            breadcrumbs: false
        },
        {
            id: 'visualizations',
            title: 'Charts/Visualizations',
            type: 'item',
            url: '/charts/admin',
            icon: icons.IconChartBar,
            breadcrumbs: false
        },
        {
            id: 'util-color',
            title: 'Portals',
            type: 'item',
            url: '/portals',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'util-shadow',
            title: 'Census/ACS data',
            type: 'item',
            url: '/census',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'assistants',
            title: 'AI Assistants',
            type: 'item',
            url: '/assistantsadmin',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
    ]
};

export default utilities;
