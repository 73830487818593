import axios from 'axios';
import showdown  from 'showdown';
let converter = new showdown.Converter();
const id = new Date().getTime();
let sortKey = 0;
const baseApiUri = process.env.REACT_APP_API_URL;

 let message = '';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

export default async function call_openai(setChatResponse, question, isSandbox, sandbox){

    console.log('CALL OPENAI');
    console.log('IS SANDBOX', isSandbox);
    console.log('QUESTION', question);
    console.log('SANDBOX', sandbox);
    //let params = {};
    let endpoint = `${baseApiUri}/ai/completion`;
     let payload = {
         "key": "09848734-8745-afrt-8745-8745873487",
         "id": id,
         "sortKey": sortKey,
         "query": question,
         "stream": true
     }
     if(isSandbox){
            payload.sandbox = sandbox;
     }
     console.log('PAYLOAD', payload)
     let response = await axios.post(endpoint, payload, {});
     console.log(response.data);
     let hasMore = true;
     while(hasMore){
        //console.log('polling completion');
        try{
            let endpoint =  `${baseApiUri}/ai/completion/${id}/${sortKey}`;
            let completion = await axios.get(endpoint);
            //console.log(completion.data);
            try{
                let status = completion.data.response.status;
                message = completion.data.response.message;
                //console.log('MESSAGE', message);
                if(message !== '' && message != null){
                    //setOpenAiResponse(message);
                    let messageHtml = converter.makeHtml(message);
                    console.log('MESSAGE HTML', messageHtml);
                    setChatResponse(messageHtml);
                    //await params.streamMessage(messageHtml.replaceAll('<a','<a target="_blank" '));
                    
                }
                if(status === 'success'){
                    hasMore = false;
                    
                }
            } catch(e){
                hasMore = false;
            }
            
            
            await sleep(100);

        } catch(e){
            hasMore = false;
            //setOpen(false);
        }
    }

}