import React, {useState, useEffect } from 'react'
import {  useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import '../dashboard/Dashboard.css';
//import Button from '@mui/material/Button';
import axios from 'axios'
import Container from '@mui/material/Container';
import Loader from "../util/Loader";
import { DataGrid } from '@mui/x-data-grid';
//icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CopyAllIcon from '@mui/icons-material/CopyAll';
//import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const baseApiUri = process.env.REACT_APP_API_URL;

export default function AssistantsAdmin(){

    const[loading, setLoading] = useState(false);
    //const[portals, setPortals] = useState();
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    let  columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
          field: 'name',
          headerName: 'Name',
          flex: 3,
          editable: true,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            renderCell: ({ row }) => (
              <strong>
                 <EditOutlinedIcon className="App-icon-spacing App-icon-cursor" onClick={() => navigate(`/assistantadmin/${row.id}`)} />
              </strong>
            ),
          }
          ,
        {
            field: 'sandbox',
            headerName: 'Sandbox',
            renderCell: ({ row }) => (
              <strong>
                 <CopyAllIcon className="App-icon-spacing App-icon-cursor" onClick={() => navigate(`/assistantadmin/${row.id}/sandbox`)} />
              </strong>
            ),
          }
          
         
      ];

     // const handleClickOpen = (portalId) => {
       // console.log('OPEN CHART ID',portalId);
   // };

    useEffect( () => {
        
        async function loadAssistants() {

            setLoading(true);
            //let endpoint = `${baseApiUri}/dashboard/check`;
            let endpoint = `${baseApiUri}/ai/assistant?key=09848734-8745-afrt-8745-8745873487`;
            console.log(endpoint);
            let res = await axios.get(endpoint);
            console.log(res.data.assistants);
            let rows = [];
            for(let i = 0; i < res.data.assistants.length; i++){
                let assistant = res.data.assistants[i];
                if(assistant.sandbox == null){
                    rows.push(assistant);
                }
            }
            //setPortals(res.data);
            setRows(rows);
            setLoading(false);
    
        }

        loadAssistants();
    } , [])

    return (
        <div style={{textAlign: "left"}}>
            <Loader open={loading} />
			<Container>
        <Box sx={{padding: '20px'}}>
        <div className="admin-main">
            <h1>AI Assistants</h1>
        </div>

        <div>
            <Box sx={{ height: 500, width: '100%', backgroundColor: '#ffffff' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
                </Box>
            </div>
        
        </Box>
        </Container>
        </div>
    )


    
}